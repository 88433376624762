body {
  font-family: 'Inter';
  font-weight: 700;
  margin-bottom: 2%;
}

.company-name {
  color: white;
  padding: 1%;
  font-size: 4rem;
  padding-top: 5%;
}

.cover {
  background-image: url("cover.jpg");
  background-size: cover;
  background-position: center;
  height: 60vh;
  position: relative;
}

.motto,
.logo,
.contact-button {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}

.contact-button {
  top: 57%;
  transform: translate(-50%, -57%);
  left: 50%;

  background-color: transparent;
  width: 214px;
  height: 53px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  border: 1px solid #b3b3b3;
}

.contact-button:hover {
  cursor: pointer;
}

.motto {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  top: 71%;
  transform: translate(-50%, -71%);
  left: 50%;
}

.grid-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 350px 350px;
}

.logo {
  height: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
}

.grid {
  padding: 0.25%;
  position: relative;
  text-align: center;
}

.section-1 {
  grid-row: 1/3;
}

.section-2 {
  grid-column: 3/5;
}

.row-1 {
  grid-row: 1/2;
}

.row-2 {
  grid-row: 2/2;
}

.services-text {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: white;
}

.grid-image {
  background-size: cover;
  background-position: center;
  height: 100%;
}

.about {
  display: block;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.about-me {
  display: grid;
  grid-template-columns: 35% 65%;
  grid-gap: 2%;
  margin-top: 3%;
}

.about-me-text {
  padding: 2%;
  font-weight: 500;
}

.about-me-photo {
  grid-column: 1/2;
}

.about-me-photo>img {
  width: 100%
}

.last-name {
  font-size: 3rem;
  text-align: center;
  display: block;
  margin-left: 9%;
  color: gray;
}

.name-section {
  position: relative;
}

.first-name {
  font-size: 4rem;
  text-align: center;
  display: block;
  margin-top: 3%;
}

.title-line.extend-right {
  width: 75%;
  left: 25%;
  right: 0;
  bottom: auto;
  top: 75px;
  position: absolute;
  height: 4px;
  background: #d7d7d7;
}

.coaching-image {
  background-position: 25%;
}

@media (max-width: 1000px) {
  .grid {
    padding: 0;
  }

  .company-name {
    color: white;
    padding: 1%;
    font-size: 3rem;
    padding-top: 20%;
  }

  .motto {
    font-size: 1.25rem;
    top: 80%;
    transform: translate(-50%, -80%);
  }

  .about {
    width: 100%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .section-2 {
    grid-column: 1/3;
    height: 350px;
  }

  .row-1 {
    grid-row: 3/4;
  }

  .row-2 {
    grid-row: 4/4;
  }

  .about-me {
    grid: 'none';
  }
}